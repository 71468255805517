import adminManual from '../../assets/pdfs/manual_de_usuario.pdf';
import superAdminManual from '../../assets/pdfs/manual_de_usuario_administradores.pdf';

const HelpPage = () => {
    return (
        <div>
            <h1>Recursos de ayuda</h1>
            <br />
            <div className="row">
                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Manuales de usuario
                            </div>
                        </div>
                        <div className="card-body">
                            <a href={adminManual} download={'manual_administradores_de_contrato.pdf'} className="btn btn-primary w-100">
                                Administrador de contrato
                            </a>
                            <br />
                            <br />
                            <a href={superAdminManual} download={'manual_administradores.pdf'} className="btn btn-primary w-100">
                                Administradores
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Videos tutoriales
                            </div>
                        </div>
                        <div className="card-body">
                            <a target="_blank" href="https://www.youtube.com/playlist?list=PL_DhsWsKElMksE59zS_PG_QGbZzidmJV5" className="btn btn-primary w-100">
                                Youtube
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpPage;